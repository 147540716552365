body {
  margin: auto;	
  background-color:#000000;
  font-family: Helvetica, sans-serif;
  
}

.App {
  text-align: center;
  position: absolute;
  width: 100%;
  background-color: #000000;   
}


.App-header {
  position:fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 75px;  
  background-color: #137547;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  /*font-family:arial;*/
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  color: #ffffff;  
  z-index:1;
  /*border: 3px solid white;*/
}

.cls_leftHeader {
	position:fixed;
	top:10px;
	left:0px;
	width:55px;
}

.cls_centerHeader  {
	position:fixed;
	margin:auto;
	top:10px;
	width:30vw;
	font-family:arial;
	font-size:30px;
	color:#ffffff;
	text-shadow: 5px 5px #000000;
	/*border: 3px solid red;*/
}

.class_rightHeader {
	position:fixed;
	top:10px;
	left:66%;	
	width:30vw;
	text-align:right;
	/*border: 3px solid red;*/
}

.App-body {
	width:90vw;
	margin:auto;
	padding:0;
	background-color: #000000;
	text-align:left;
	color:#ffffff;
	font-size:23px;
		
}

.cls_splitter {
	width:100%;
	display:flex;
	flex-direction:row;
}


.App-footer{
	width:100%;
	text-align:center;
	color:grey;
	font-size:15px;
	font-family:arial;
	text-decoration:none;
}

.cls_menulistHolder {
	position:fixed;
	top:70px;
	left:0px;
	background-color: #30302f;
	width:190px;
	z-index:4;
	padding-left:10px;
}

.cls_about {
	position:relative;
	top:100px;
	height: 100%;
	width:75%;
	text-align:left;
	margin:auto;
	/*border: 3px solid red;*/
	border: 3px solid red;
	padding-left: 20px;
	padding-right:2px;
	border-radius: 75px 20px;
}


.cls_menuOptionList {
	text-align:left;
	height:35px;
	width:190px;
	font-family:sans-serif;
	font-size:25px;
	font-weight:regular;
}

a:hover, a:link, a:visited, .cls_menuOptionList:hover {
	background-color: red;
	text-decoration:none;
	cursor: pointer;
	/*z-index:4;*/
	
}


.cls_imageFlash {
	margin: auto;	
	width:60%;
	height:100vh;	
	/*this works */	
	animation: fadeInAnimation ease 10s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	
}	

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.cls_serviceList {
	margin:auto;
	border-radius: 8px;
	width: 70%;
	height:1000px;;
	text-align:left;
	font-size:20px;
	font-weight:bold;
	padding-left:1px;
	/*border: 3px solid red;*/
}

.cls_homeService {
	position: fixed;
	top: 75px;
	right:0;
	width:22%; 
	height:auto;
	border-radius: 8px;
	text-align:left;
	padding-left:20px;
	background-color:#000000;
	opacity: 0.9;
}

.cls_iconImage {
	position:fixed;
	left:10px;
	top:15px;
	width:50px;
	height:50px;
	z-index:1;
}

.cls_portfolio {
	position:relative;
	top:75px;
	margin:auto;
}

.cls_imagePortfolio{
	position:relative;
	width: 800px;
	height:800px;
	margin:auto;
	border-radius: 150px;
}

.cls_contactImage {
	border: 10px solid #ffffff;
	width:auto;
}


.cls_contact, .cls_contact:hover, a:hover, a:link {
	border-radius: 8px;
	background-color: #ffffff;
	color: black;
	text-decoration:none;
	/*text-align:center;*/
	font-size:23px;
	font-weight: bold;
	cursor: hand;	
	z-index: 1;	
}


.cls_form {
	position:relative;
	top: 70px;
	width:50%;
	height:650px;
	margin: auto;	
	/*border: 10px solid red;*/
}

.cls_formRow {
	padding:1.2%;
	display:flex;
	flex-direction:row;
}


.cls_label {
	width: 20%;
	text-align:left;
}

.cls_field{
	width: 80%;
	text-align:left;
}

.cls_label, .cls_field  {
	/*width: 50%;*/
	color:#ffffff;
	left-align:right;
	font-family:Arial;
	font-weight:750;
	/*border: 10px solid red;*/
}


li, p, h2 {
	width:90%;	
}

img {
	border-radius: 8px;
}

input {
	height:25px
}

textarea {
	height: 200px;
}

input, textarea {
	width:280px;
	font-size:30px;
}

iframe {
	width:80%;
	height:1000px;
	border-radius:8px;
}


.cls_submit {
	border-radius: 8px;
	height:50px;
	width:250px;
	background-color:yellow;
	font-family:sans-serif;
	font-size:30px;
	font-weight:750;
	color:#000000;
	cursor:pointer;
	
}


.App-link {
  color: #61dafb;
}

.cls_bottom {
	display:none;
	position: fixed;
	left:0;
	bottom: 0;
	width: 100%;
	height: 55px;
	padding: 10px;
	text-align: center;
	margin:auto;
	background-color: red;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 1026px) {
	
	.App {
		width:100%;
		font-size:23px;
	}
	
	div {
		font-size:20px;		
	}
	
	.App-body {
		width: 95%;
		text-align:left;
		font-size:23px;
		margin:auto;
		/*padding:2px;*/
	}
	
	
	.cls_homeService, .App-footer {
		position:relative;
		width:100%;
		margin:auto;
		left:0px;
	}
	
	.cls_menuOptionList {
		font-family:sans-serif;
		font-size:20px;
	}
	

	.cls_centerHeader {
		display:none;
	}
	
	
	.cls_bottom {
		display:block;
		position: fixed;
		left:0;
		bottom: 0;
		width: 100%;
		height: 55px;
		padding: 10px;
		text-align: center;
		margin:auto;
		background-color: #b33127;
	}
	
	
	.cls_imageFlash, .cls_serviceList {
		border-radius: 8px;
		width: 100%;
		height: 100vh;
	}
	
	.cls_about {
		width:auto;
	}

	.cls_portfolio, .cls_imagePortfolio{
		width: 100%;
		height:100hv;	
	}
	
	
	
	.cls_form {
		position:relative;
		top:0px;
		width:90%;
		height:100%;
	}
	
	.cls_formRow {
		width:90%;
		display:flex;
		flex-direction:column;
	}
	
	.cls_label, .cls_field {
		width: 95%;	
		text-align:left;
		margin:auto;
	}
	
	iframe {
		width:100%;
	}
	
}